
<template>
	<div>
		<b-card-countdown
			stage="evaluación de propuestas"
			:stage-closed="stageClosed"
			:deadline="deadline"
			:start-date="startDate"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-card>
			<b-tabs align="left">
				<b-tab
					name="info"
					active
				>
					<template #title>
						<span class="d-none d-sm-inline">Propuestas</span>
					</template>

					<tab-proposals
						:tender-id="tenderId"
						:is-published="tenderData.is_published"
						:is-disabled="isDisabled"
						:competition-data="competitionData"
					/>
				</b-tab>

				<b-tab name="matrix">
					<template #title>
						<span class="d-none d-sm-inline">Matriz de Evaluación</span>
					</template>

					<tab-matrix :tender-data="tenderData" />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import { Stages, TenderStates } from "@core/utils/data"
import { ref, onBeforeMount } from "@vue/composition-api"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import TabMatrix from "./matrix/TabMatrix.vue"
import TabProposals from "./proposals/TabCompetitionProposals.vue"

export default {
	components: {
		BCardCountdown,
		TabMatrix,
		TabProposals
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true,
			default: () => []
		},
		competitionData: {
			type: Array,
			required: true,
			default: () => []
		},
		tenderData: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	setup(props) {
		const { FINALIZADO, EN_REVISION } = TenderStates

		const scheduleData = ref(props.scheduleData)
		const tenderData = ref(props.tenderData)
		const deadline = ref(null)
		const startDate = ref(null)
		const isDisabled = ref(false)
		const stageClosed = ref(false)

		const hidden = ref(false)

		onBeforeMount(() => {
			setData()
		})

		if (tenderData.value.tender_state_id !== EN_REVISION) {
			stageClosed.value = true
			isDisabled.value = true
		}

		const setData = () => {
			const evaluation = scheduleData.value.find(
				(item) => parseInt(item.tender_stage_id) === Stages.EVALUACION_PROPUESTA
			)

			deadline.value = evaluation.actual_end_date || ""
			startDate.value = evaluation.actual_start_date || ""
		}

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			if (tenderData.value.tender_state_id !== EN_REVISION) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		return {
			deadline,
			startDate,
			hidden,
			isDisabled,
			stageClosed,

			handleTimeFinished,
			handleNotYetStarted
		}
	}
}
</script>