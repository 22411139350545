<template>
	<b-card no-body>
		<app-collapse type="margin">
			<app-collapse-item
				title="MATRIZ DE VARIACIONES DE PRESUPUESTO"
				isVisible
				class="text-primary"
			>
				<b-row>
					<b-col cols="12">
						<b-table
							class="table-sm"
							ref="refLimitTable"
							:fields="fieldsLimit"
							:items="itemsLimit"
							responsive
							bordered
							fiexed
						></b-table>
					</b-col>

					<b-col cols="12">
						<b-table
							class="table-sm"
							ref="refVariationTable"
							:fields="fieldsVariation"
							:items="itemsVariation"
							responsive
							bordered
							fiexed
						>
							<template #cell(variation)="data">{{ formatCurrency(data.item.variation, true) }}</template>

							<template #cell(result)="data">
								<b-badge
									:variant="`light-${ data.item.onLimit ? 'success' : 'danger'}`"
								>{{ data.item.result }}</b-badge>
							</template>
						</b-table>
					</b-col>
				</b-row>
			</app-collapse-item>

			<app-collapse-item
				title="MATRIZ DE PROPUESTA ECONÓMICA"
				isVisible
				class="text-primary"
			>
				<b-row>
					<b-col>
						<b-table
							class="table-sm"
							ref="refEconomicTable"
							:fields="fieldsEco"
							:items="itemsEco"
							responsive
							bordered
							fiexed
						>
							<template #cell(details)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.details }}</span>
							</template>

							<template #cell(weighting)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.weighting }}</span>
							</template>

							<template #cell(percentage)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.percentage }}</span>
							</template>
							<template
								v-for="(key, index) in fieldsEco"
								:slot="`cell(bidder_score_${index+1})`"
								slot-scope="data"
							>
								<span
									:key="index"
									:class="{'text-primary font-weight-bold': data.item.last }"
								>{{ data.item[`bidder_score_${index+1}`] }}</span>
							</template>
						</b-table>
					</b-col>
				</b-row>
			</app-collapse-item>

			<app-collapse-item
				title="MATRIZ DE PROPUESTA SIG"
				class="text-primary"
				isVisible
			>
				<b-row>
					<b-col>
						<b-table
							class="table-sm"
							ref="refSigTable"
							:fields="fieldsSig"
							:items="itemsSig"
							responsive
							bordered
							fiexed
						>
							<template #cell(category)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.category }}</span>
							</template>

							<template #cell(weighting)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.weighting }}</span>
							</template>

							<template #cell(percentage)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.percentage }}</span>
							</template>
							<template
								v-for="(key, index) in fieldsSig"
								:slot="`cell(bidder_score_${index+1})`"
								slot-scope="data"
							>
								<span
									:key="index"
									:class="{'text-primary font-weight-bold': data.item.last }"
								>{{ data.item[`bidder_score_${index+1}`] }}</span>
							</template>
						</b-table>
					</b-col>
				</b-row>
			</app-collapse-item>

			<app-collapse-item
				title="MATRIZ DE PROPUESTA TÉCNICA"
				class="text-primary"
				isVisible
			>
				<b-row>
					<b-col>
						<b-table
							class="table-sm"
							ref="refTechnicalTable"
							:fields="fieldsTec"
							:items="itemsTec"
							responsive
							bordered
							fiexed
						>
							<template #cell(category)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.category }}</span>
							</template>

							<template #cell(weighting)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.weighting }}</span>
							</template>

							<template #cell(percentage)="data">
								<span :class="{'text-primary font-weight-bold': data.item.last }">{{ data.item.percentage }}</span>
							</template>
							<template
								v-for="(key, index) in fieldsTec"
								:slot="`cell(bidder_score_${index+1})`"
								slot-scope="data"
							>
								<span
									:key="index"
									:class="{'text-primary font-weight-bold': data.item.last }"
								>{{ data.item[`bidder_score_${index+1}`] }}</span>
							</template>
						</b-table>
					</b-col>
				</b-row>
			</app-collapse-item>
		</app-collapse>
	</b-card>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api"
import { VBTooltip } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { formatCurrency } from "@core/utils/filter"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		BButtonUploadFile,
		BButtonLoading
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		tenderData: {
			type: Object,
			default: () => {},
			required: true
		}
	},
	setup(props) {
		const tenderData = ref(props.tenderData)

		const fieldsVariation = ref([])
		const itemsVariation = ref([])
		const fieldsLimit = ref([])
		const itemsLimit = ref([])
		const fieldsEco = ref([])
		const itemsEco = ref([])
		const itemsSig = ref([])
		const fieldsSig = ref([])
		const itemsTec = ref([])
		const fieldsTec = ref([])

		onMounted(async () => {
			setStageDataTable()
		})

		const setStageDataTable = () => {
			fieldsLimit.value = [
				...(tenderData.value.tables?.limitTable?.headers || [])
			]
			itemsLimit.value = [...(tenderData.value.tables?.limitTable?.body || [])]

			fieldsVariation.value = [
				...(tenderData.value.tables?.variationTable?.headers || [])
			]
			itemsVariation.value = [
				...(tenderData.value.tables?.variationTable?.body || [])
			]

			fieldsEco.value = [
				...(tenderData.value.tables?.economicTable?.headers || [])
			]
			itemsEco.value = [...(tenderData.value.tables?.economicTable?.body || [])]

			fieldsSig.value = [...(tenderData.value.tables?.sigTable?.headers || [])]
			itemsSig.value = [...(tenderData.value.tables?.sigTable?.body || [])]

			fieldsTec.value = [...(tenderData.value.tables?.tecTable?.headers || [])]
			itemsTec.value = [...(tenderData.value.tables?.tecTable?.body || [])]
		}

		return {
			fieldsVariation,
			itemsVariation,
			fieldsLimit,
			itemsLimit,
			itemsEco,
			fieldsEco,
			itemsSig,
			fieldsSig,
			itemsTec,
			fieldsTec,

			formatCurrency
		}
	}
}
</script>

<style>
</style>