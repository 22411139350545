
<template>
	<component :is="'div'">
		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="tenderData === undefined"
		>
			<h4 class="alert-heading">Error al obtener los datos de la licitación</h4>
			<div class="alert-body">
				No se encontró ninguna licitación con este id. Verificar en la
				<b-link
					class="alert-link"
					:to="{ name: 'tenders-list'}"
				>Lista de licitaciones</b-link>para buscar otra licitación.
			</div>
		</b-alert>

		<b-tabs
			pills
			v-model="activeTab"
			v-if="tenderData"
		>
			<!-- Tab: Consultations -->
			<b-tab
				name="consultations"
				:disabled="[EN_CURSO, FINALIZADO, EN_REVISION].includes(tenderData.tender_state_id) ? false : true"
			>
				<template #title>
					<feather-icon
						:icon="[FINALIZADO, EN_REVISION].includes(tenderData.tender_state_id) ? 'InboxIcon' : 'LockIcon'"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Consultas</span>
				</template>

				<tender-edit-tab-consultations
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:tender-data="tenderData"
					:schedule-data="tenderData.schedules"
					@refetch-tender-data="refetchTenderData"
				/>
			</b-tab>

			<!-- Tab: Evaluations -->
			<b-tab
				name="evaluations"
				:disabled="[FINALIZADO, EN_REVISION].includes(tenderData.tender_state_id) ? false : true"
			>
				<template #title>
					<feather-icon
						:icon="[FINALIZADO, EN_REVISION].includes(tenderData.tender_state_id) ? 'SearchIcon':'LockIcon'"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Evaluación Propuestas</span>
				</template>

				<tender-edit-tab-evaluations
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:schedule-data="tenderData.schedules"
					:competition-data="tenderData.competitions"
					:tender-data="tenderData"
				/>
			</b-tab>

			<!-- Tab: Approvals -->
			<b-tab
				name="approvals"
				:disabled="[FINALIZADO, EN_REVISION].includes(tenderData.tender_state_id) ? false : true"
			>
				<template #title>
					<feather-icon
						:icon="[FINALIZADO, EN_REVISION].includes(tenderData.tender_state_id)? 'AwardIcon' : 'LockIcon'"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Aprobación y Selección</span>
				</template>

				<tender-edit-tab-approvals
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:tender-data="tenderData"
					:schedule-data="tenderData.schedules"
				/>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import { TenderStates } from "@core/utils/data"
import TenderEditTabConsultations from "./consultations/TenderEditTabConsultations.vue"
import TenderEditTabEvaluations from "./evaluations/EvaluationEdit.vue"
import TenderEditTabApprovals from "./approvals/TenderEditTabApprovals.vue"
import tenderStoreModule from "../tenderStoreModule"
import useNotifications from "@notifications"
import { ref, onUnmounted, onMounted } from "@vue/composition-api"
import router from "@/router"
import store from "@/store"

export default {
	components: {
		TenderEditTabConsultations,
		TenderEditTabEvaluations,
		TenderEditTabApprovals
	},
	computed: {
		activeTab: {
			set(val) {
				let query = { ...router.currentRoute.query }
				query.tab = val
				router.replace({ query: query })
			},
			get() {
				return parseInt(router.currentRoute.query.tab)
			}
		}
	},
	setup() {
		const { toastNotification } = useNotifications()
		// REFS
		const { EN_CURSO, EN_REVISION, FINALIZADO } = TenderStates
		const tenderData = ref(null)

		const TENDER_APP_STORE_MODULE_NAME = "app-tender"

		// REGISTER MODULE
		if (!store.hasModule(TENDER_APP_STORE_MODULE_NAME))
			store.registerModule(TENDER_APP_STORE_MODULE_NAME, tenderStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(TENDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(TENDER_APP_STORE_MODULE_NAME)
		})

		const refetchTenderData = () => {
			store
				.dispatch("app-tender/fetchTenderReview", {
					id: router.currentRoute.params.id
				})
				.then((response) => {
					if (response.data === "error") {
						tenderData.value = undefined
					} else {
						tenderData.value = response.data.tender
					}
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
					if (error.response.status === 404) {
						tenderData.value = undefined
					}
					router.push({ name: "tenders-list" })
				})
		}

		onMounted(() => {
			refetchTenderData()
		})

		return {
			EN_REVISION,
			FINALIZADO,
			EN_CURSO,
			tenderData,
			refetchTenderData
		}
	}
}
</script>