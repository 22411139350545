import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useTabConsultationsList(id) {

    // Use toast
    const toast = useToast();

    const refConsultationListTable = ref(null);

    const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    let tableColumns = [
        { key: 'code', sortable: true, label: 'CÓDIGO', ...styleColumns },
        { key: 'bidder_name', sortable: true, label: 'POSTOR', thClass: styleColumns.thClass },
        { key: 'text', sortable: true, label: 'CONSULTA', thClass: styleColumns.thClass },
        { key: 'is_absolved', sortable: true, label: 'ESTADO DE <br/> ABSOLUCIÓN', ...styleColumns },
        { key: 'createdAt', sortable: true, label: 'FECHA DE <br/> CONSULTA', ...styleColumns },
        { key: 'absolvedAt', sortable: true, label: 'FECHA DE <br/> ABSOLUCIÓN', ...styleColumns },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
    ];

    const perPage = ref(10);
    const totalConsultations = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refConsultationListTable.value ? refConsultationListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalConsultations.value,
        }
    });

    const refetchData = () => {
        refConsultationListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });

    const fetchConsultations = (ctx, callback) => {

		const params = {
			tender_id: id,
			q: searchQuery.value,
			perPage: perPage.value,
			page: currentPage.value,
			sortBy: sortBy.value,
			sortDesc: isSortDirDesc.value,
		}

        store
            .dispatch('app-consultation/fetchConsultations', { params })
            .then(response => {
                const { consultations, total } = response.data;
                callback(consultations);
                totalConsultations.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de consultas',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchConsultations,
        tableColumns,
        perPage,
        currentPage,
        totalConsultations,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refConsultationListTable,

        refetchData
    }
}