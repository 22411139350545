import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchConsultations(ctx, { params }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/consultations`, { params })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateConsultation(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/consultations/${id}/absolve`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}