<template>
	<b-card>
		<div v-if="!deadline && !startDate">
			<span class="text-warning">
				<strong>Cronograma no establecido</strong>
			</span>
		</div>
		<div v-else-if="stageClosed">
			<b-alert
				show
				variant="danger"
			>
				<div class="alert-body">
					<feather-icon
						class="mr-25"
						icon="LockIcon"
					/>
					<span class="ml-25">Etapa cerrada</span>
				</div>
			</b-alert>
		</div>
		<div
			v-else
			class="d-flex justify-content-between align-items-center"
		>
			<span>
				<h5 class="text-info font-weight-bolder">Etapa de {{ stage }}</h5>
				<small>
					<strong>Fecha límite :</strong>
					{{ moment(deadline).format("DD-MM-YYYY H:mm a") }}
				</small>
			</span>
			<div>
				<span v-if="!isTimeFinished">
					<strong>
						<span class="text-info bold">{{ countdownLabel }} :</span>
						{{ tiempoRestante }}
					</strong>
				</span>
				<span
					v-else
					class="text-danger"
				>
					<strong>Tiempo Agotado</strong>
				</span>
			</div>
		</div>
	</b-card>
</template>
  
<script>
import moment from "moment"

export default {
	props: {
		stage: {
			type: String,
			required: true
		},
		startDate: {
			type: String,
			required: true
		},
		deadline: {
			type: String,
			required: true
		},
		stageClosed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			moment,
			tiempoRestante: "",
			isTimeFinished: false,
			countdownLabel: "Termina en",
			notified: {
				tenMinutes: false,
				fiveMinutes: false,
				threeMinutes: false
			}
		}
	},
	methods: {
		actualizarCuentaRegresiva() {
			const startDate = new Date(this.startDate).getTime()
			let deadline = new Date(this.deadline).getTime()
			deadline -= 10000
			const ahora = new Date().getTime()
			let distancia = deadline - ahora

			if (ahora < startDate) {
				this.$emit("not-yet-started", true)
			} else {
				this.$emit("not-yet-started", false)
				const dias = Math.floor(distancia / (1000 * 60 * 60 * 24))
				const horas = Math.floor(
					(distancia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				)
				const minutos = Math.floor((distancia % (1000 * 60 * 60)) / (1000 * 60))
				const segundos = Math.floor((distancia % (1000 * 60)) / 1000)

				this.tiempoRestante = `${this.formatearNumero(dias)} ${
					dias === 1 ? "día" : "días"
				} ${this.formatearNumero(horas)}:${this.formatearNumero(
					minutos
				)}:${this.formatearNumero(segundos)}`

				if (distancia < 0) {
					clearInterval(this.intervalo)
					this.isTimeFinished = true
					this.$emit("time-finished")
				} else {
					this.checkNotifications(minutos)
				}
			}

			// this.$emit("countdown-status", {
			// 	notStarted: ahora < startDate,
			// 	inProgress: ahora >= startDate && ahora < deadline,
			// 	finished: ahora >= deadline
			// })
		},
		checkNotifications(minutos) {
			if (!this.notified.tenMinutes && minutos <= 10) {
				this.notified.tenMinutes = true
				this.$emit("notification", {
					tenMinutes: true,
					fiveMinutes: false,
					threeMinutes: false
				})
			} else if (!this.notified.fiveMinutes && minutos <= 5) {
				this.notified.fiveMinutes = true
				this.$emit("notification", {
					tenMinutes: false,
					fiveMinutes: true,
					threeMinutes: false
				})
			} else if (!this.notified.threeMinutes && minutos <= 3) {
				this.notified.threeMinutes = true
				this.$emit("notification", {
					tenMinutes: false,
					fiveMinutes: false,
					threeMinutes: true
				})
			}
		},
		formatearNumero(num) {
			return num < 10 ? `0${num}` : num
		}
	},
	mounted() {
		this.actualizarCuentaRegresiva()
		this.intervalo = setInterval(this.actualizarCuentaRegresiva, 1000)
	},
	beforeDestroy() {
		clearInterval(this.intervalo)
	}
}
</script>
  
<style scoped>
.my-3 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
</style>
  