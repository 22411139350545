import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchFiles(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/files', { params: payload })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchFilesBidders(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/files/bidders', { params: payload })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteFile(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/files/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}