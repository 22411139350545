<template>
	<div>
		<b-card-countdown
			stage="aprobación y selección del ganador"
			:deadline="deadline"
			:start-date="startDate"
			:stage-closed="stageClosed"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-card>
			<b-form>
				<b-row>
					<b-col
						cols="12"
						class="mb-2"
					>
						<label for="description">
							<strong>RESUMEN DE EVALUACIÓN</strong>
						</label>
						<b-table
							class="table-sm"
							:items="items"
							:fields="fields"
							bordered
						>
							<template #cell(result)="data">
								<span v-if="!parseFloat(data.item.total)">-</span>
								<b-badge
									v-else
									:variant="`light-${ data.index === 0 ? 'success' : 'info'}`"
								>{{ data.index === 0 ? `${ data.index + 1}° Mayor puntaje` : `${ data.index + 1}°` }}</b-badge>
							</template>
						</b-table>
					</b-col>

					<b-col
						cols="12"
						class="mb-2"
					>
						<label for="winner_selector">
							<strong>SELECCIÓN DE POSTOR GANADOR</strong>
						</label>
						<v-select
							id="winner_selector"
							v-model="formData.winner"
							:options="options.tenderBidders"
							:disabled="isDisabled"
							:readonly="isDisabled"
							placeholder="Seleccione"
						></v-select>
					</b-col>

					<b-col
						cols="12"
						class="mb-2"
					>
						<label for="description">
							<strong>JUSTIFICACIÓN</strong>
						</label>
						<b-form-textarea
							id="description"
							v-model="formData.justification"
							placeholder="Ingrese texto ..."
							rows="5"
							:disabled="isDisabled"
							:readonly="isDisabled"
							trim
						></b-form-textarea>
					</b-col>

					<b-col
						cols="12"
						md="6"
						class="mb-2"
					>
						<label>
							<strong>ARCHIVOS DE EVIDENCIA</strong>
						</label>
						<b-button-upload-file
							:tender-id="tenderId"
							:is-disabled="isDisabled"
							:tender-props="{ isWinnerEvidence: true }"
							:button-props="{ text: ` ${ isDisabled ? 'VER' : 'CARGAR'} ARCHIVOS`, icon: 'PaperclipIcon', block: true }"
							:modal-props="{ title: 'ARCHIVOS DE EVIDENCIA' }"
							:new-extensions="true"
							accepted-files=".pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.jpg,.jpeg,.png"
						/>
					</b-col>

					<b-col cols="12">
						<hr />
					</b-col>

					<b-col cols="12 mt-2">
						<b-button-loading
							text="FINALIZAR APROBACIÓN Y SELECCIÓN"
							size="lg"
							:disabled="isDisabled"
							:block="true"
							@process-action="sendApproval"
							:processing="processing"
						/>
					</b-col>
				</b-row>
			</b-form>
		</b-card>
	</div>
</template>

<script>
import { Stages, TenderStates } from "@core/utils/data"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import { ref, onBeforeMount } from "@vue/composition-api"
import useNotifications from "@notifications"
import vSelect from "vue-select"
import axios from "@axios"
import router from "@/router"
import VueSweetalert2 from "vue-sweetalert2"
import Vue from "vue"

Vue.use(VueSweetalert2)

export default {
	components: {
		BCardCountdown,
		BButtonUploadFile,
		BButtonLoading,

		vSelect
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true
		},
		tenderData: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		const {
			swalNotification,
			swalNotificationCloser,
			swalNotificationTimer,
			toastNotification
		} = useNotifications()
		const scheduleData = ref(props.scheduleData)
		const tenderData = ref(props.tenderData)
		const tenderId = ref(props.tenderId)
		const deadline = ref(null)
		const startDate = ref(null)
		const processing = ref(false)
		const winnerSelected = ref(null)
		const isDisabled = ref(false)
		const stageClosed = ref(false)

		const { EN_REVISION } = TenderStates

		const formData = ref({
			winner: null,
			justification: null
		})

		const options = ref({})
		const items = ref([])
		const fields = ref([])

		onBeforeMount(() => {
			setData()
		})
		const approval = scheduleData.value.find(
			(item) => parseInt(item.tender_stage_id) === Stages.APROBACION
		)
		deadline.value = approval.actual_end_date || ""
		startDate.value = approval.actual_start_date || ""

		const setData = async () => {
			await getSelectFilters()

			let winner = null
			if (tenderData.value.winner_id) {
				winner = options.value.tenderBidders.find(
					(item) => item.value === tenderData.value.winner_id
				)
			}

			formData.value.justification = tenderData.value.justification
			formData.value.winner = winner

			fields.value = [...(tenderData.value.tables?.winnerTable?.headers || [])]
			items.value = [...(tenderData.value.tables?.winnerTable?.body || [])]
		}

		const getSelectFilters = async () => {
			const response = await axios.get(
				`/selectors/tender-bidders?tender_id=${tenderId.value}`
			)
			options.value = {
				...response.data
			}
		}

		if (tenderData.value.tender_state_id !== EN_REVISION) {
			isDisabled.value = true
			stageClosed.value = true
		}

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			if (tenderData.value.tender_state_id !== EN_REVISION) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		const sendApproval = () => {
			if (formData.value && !formData.value.justification) {
				processing.value = false
				const message = "Debe completar la justificación"
				toastNotification(message, false)
				return
			}

			if (formData.value && !formData.value.winner) {
				processing.value = false
				const message = "Debe seleccionar al postor ganador"
				toastNotification(message, false)
				return
			}

			processing.value = true

			Vue.swal({
				title: "¿Está seguro(a) de finalizar la aprobación?",

				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, continuar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (result.value) {
					try {
						const payload = {
							tender_id: tenderId.value,
							justification: formData.value.justification,
							winner_id: formData.value.winner
								? formData.value.winner.value
								: null,
							fromParams: true
						}

						const response = await axios.put(
							`/tenders/${tenderId.value}/approvals`,
							payload
						)
						swalNotification(response.data.message)
						router.push({ name: "tenders-list" })
					} catch (error) {
						const message =
							error.response.data.message || "Error al finalizar evaluación"
						swalNotification(message, false)
					} finally {
						processing.value = false
					}
				} else {
					processing.value = false
				}
			})
		}

		return {
			deadline,
			startDate,
			isDisabled,
			stageClosed,
			items,
			fields,
			formData,
			options,
			processing,

			handleTimeFinished,
			handleNotYetStarted,

			sendApproval
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>