<template>
	<b-card no-body>
		<b-row>
			<b-col>
				<b-table
					:items="items"
					:fields="fields"
					primary-key="id"
					:sort-by.sync="sortBy"
					:sort-desc.sync="isSortDirDesc"
					class="position-relative"
					show-empty
					striped
					empty-text="No se encontraron registros coincidentes"
				>
					<template #cell(state)="data">
						<div class="text-nowrap">
							<b-badge :variant="`light-${data.item.state_color}`">{{ data.item.state }}</b-badge>
						</div>
					</template>

					<template #cell(documents)="data">
						<div class="text-nowrap">
							<b-button-upload-file
								:is-published="isPublished"
								:competition-id="data.item.competition_id"
								:competition-props="{ isSpecific: true }"
								:button-props="{ text: 'VER DOCUMENTOS', icon: 'PaperclipIcon', size: 'sm'}"
								:modal-props="{ title: 'DOCUMENTOS ESPECÍFICOS' }"
							/>
						</div>
					</template>

					<template #cell(actions)="data">
						<b-button
							variant="outline-primary"
							:to="{ 
								name: 'tenders-bidder-review', 
								params: {
									id: data.item.tender_id,
									competition_id: data.item.competition_id
								}
					}"
						>{{ isDisabled ? 'Ver Revisión' : 'Revisar Propuesta' }}</b-button>
					</template>
				</b-table>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { ref, onUnmounted, onBeforeMount, computed } from "@vue/composition-api"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import vSelect from "vue-select"
import moment from "moment"
import axios from "@axios"

export default {
	components: {
		BButtonUploadFile,
		vSelect
	},
	data() {
		return {
			moment
		}
	},
	props: {
		isPublished: {
			type: Number,
			required: true
		},
		isDisabled: {
			type: Boolean,
			default: false
		},
		competitionData: {
			type: Array,
			required: true,
			default: () => []
		},
		tenderId: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const competitionData = ref(props.competitionData)
		const tenderId = ref(props.tenderId)
		const perPage = ref(10)
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref("id")
		const isSortDirDesc = ref(false)

		const styleColumns = {
			thClass: "text-center",
			tdClass: "text-center"
		}

		const items = ref([])

		const fields = ref([
			{ key: "bidder_name", label: "POSTOR", thClass: styleColumns.thClass },
			{ key: "state", label: "ESTADO DE LICITACIÓN", ...styleColumns },
			{ key: "finishedAt", label: "FECHA DE ENVÍO", ...styleColumns },
			{ key: "documents", label: "DOCUMENTOS ESPECÍFICOS", ...styleColumns },
			{ key: "actions", label: "OPCIONES", ...styleColumns }
		])

		onBeforeMount(async () => {
			setData()
		})

		const setData = () => {
			items.value = competitionData.value.map((item) => ({
				competition_id: item.id,
				bidder_name: item.bidder.bidder_name,
				tender_id: tenderId.value,
				state: item.competition_state.name,
				state_color: item.competition_state.color,
				finishedAt: item.finishedAt
					? moment(item.finishedAt).format("DD-MM-YYYY H:mm")
					: "-"
			}))
		}

		return {
			items,
			fields,

			perPage,
			currentPage,
			perPageOptions,
			sortBy,
			isSortDirDesc
		}
	}
}
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>