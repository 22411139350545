<template>
	<div>
		<b-button
			:id="buttonProps.id || 'idFile'"
			v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			:size="buttonProps.size || 'md'"
			:block="buttonProps.block || false"
			:variant="buttonProps.variant || 'outline-primary'"
			:class="{ 'btn-icon rounded-circle': buttonProps.noText }"
			@click="getFiles"
		>
			<feather-icon
				size="1x"
				:icon="buttonProps.icon || 'UploadIcon'"
			/>
			{{ !buttonProps.noText && buttonProps.text ? buttonProps.text || 'CARGAR ARCHIVOS' : '' }}
		</b-button>

		<b-modal
			ref="modal"
			:title="modalProps.title || 'DOCUMENTOS'"
			:size="modalProps.size || 'xl'"
			modal-class="modal-primary"
			no-close-on-backdrop
			no-close-on-esc
			centered
		>
			<div v-if="documents.length > 0">
				<label class="description-modal mt-1 mb-1">Lista de documentos requeridos</label>
				<ul>
					<li
						v-for="(document, index) in documents"
						:key="index"
						class="content-modal"
					>{{ document.name }}</li>
				</ul>
			</div>

			<template v-if="!isDisabled && canUpload">
				<hr />

				<div class="my-1">
					<vue-dropzone
						ref="dropzone"
						id="dropzone"
						:options="dropzoneOptions"
						:headers="dropzoneHeaders"
						:useCustomSlot="true"
						@vdropzone-sending-multiple="vsending"
						@vdropzone-success-multiple="vsuccess"
						@vdropzone-error="verror"
						@vdropzone-removed-file="vremovedfile"
					>
						<div class="dropzone-custom-content">
							<h4 class="dropzone-custom-title">
								<strong>¡Arrastre o haga click aquí para cargar contenido!</strong>
							</h4>
							<div
								v-if="!newExtensions"
								class="subtitle"
							>archivos permitidos PDF o EXCEL</div>
							<div class="small text-muted">peso máximo 25MB</div>
						</div>
					</vue-dropzone>
				</div>

				<hr />
			</template>

			<table
				v-if="canWatch"
				class="table table-bordered text-center w-100 mt-2 mb-1"
			>
				<thead>
					<tr>
						<th>#</th>
						<th colspan="2">Archivo</th>
						<th>Fecha de carga</th>
						<th>Opciones</th>
					</tr>
				</thead>

				<tbody>
					<tr v-if="!files.length">
						<td colspan="5">NO HAY NINGÚN ARCHIVO CARGADO.</td>
					</tr>
					<tr
						v-for="(item, index) in files"
						:key="index"
					>
						<td>
							<span class="text-nowrap">{{ index + 1 }}</span>
						</td>
						<td colspan="2">{{ item.original_name }}</td>
						<td>
							<span class="text-nowrap">{{ moment(item.createdAt).format('DD/MM/YYYY HH:mm A') }}</span>
						</td>
						<td>
							<div class="justify-content-center align-items-center">
								<b-button
									:href="item.url"
									target="_blank"
									v-ripple.400="'rgba(40, 199, 111, 0.15)'"
									variant="flat-primary"
									class="btn-icon rounded-circle"
									title="Descargar"
								>
									<feather-icon icon="DownloadIcon" />
								</b-button>

								<b-button
									v-if="!isDisabled && canDelete"
									v-ripple.400="'rgba(40, 199, 111, 0.15)'"
									variant="flat-danger"
									class="btn-icon rounded-circle"
									title="Eliminar"
									@click="deleteFile(item.id)"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<template #modal-footer="{ cancel }">
				<b-button
					variant="outline-secondary"
					@click="cancel(); reset()"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import uploadFileStoreModule from "./uploadFileStoreModule"
import useNotifications from "@notifications"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import { getUserRoles } from "@/auth/utils"
import Ripple from "vue-ripple-directive"
import vue2Dropzone from "vue2-dropzone"
import store from "@/store"
import moment from "moment"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

export default {
	components: {
		vueDropzone: vue2Dropzone
	},
	props: {
		isPublished: {
			type: Number,
			default: 0
		},
		tenderId: {
			type: Number
		},
		competitionId: {
			type: Number
		},
		tenderProps: {
			type: Object,
			default: () => ({
				isSpecific: false,
				isGeneral: false,
				isFromInvitation: false,
				isWinnerEvidence: false
			})
		},
		competitionProps: {
			type: Object,
			default: () => ({
				isSpecific: false
			})
		},
		consultationProps: {
			type: Object,
			default: () => ({
				consultationId: null,
				isConsultation: false,
				isAbsolved: false,
				isOwner: false,
				ownerCompetitionId: null
			})
		},
		proposalProps: {
			type: Object,
			default: () => ({
				isEconomic: false,
				isTechnical: false,
				isSig: false,
				proposalId: null,
				economicProposalId: null,
				economicProposalBidderId: null
			})
		},
		visitProps: {
			type: Object,
			default: () => ({
				visitId: null,
				isVisit: false
			})
		},
		modalProps: {
			type: Object,
			default: () => ({
				title: "DOCUMENTOS",
				size: "xl"
			})
		},
		buttonProps: {
			type: Object,
			default: () => ({
				id: "",
				variant: "outline-primary",
				block: false,
				icon: "UploadIcon",
				size: "md",
				noText: false,
				text: "CARGAR ARCHIVOS"
			})
		},
		acceptedFiles: {
			type: String,
			default: ".pdf,.xls,.xlsx, .doc, .docx, .ppt, .pptx, .zip, .rar"
		},
		newExtensions: {
			type: Boolean,
			default: false
		},
		isDisabled: {
			type: Boolean,
			default: false
		},
		onlyView: {
			type: Boolean,
			default: false
		}
	},
	directives: {
		Ripple
	},
	data() {
		return {
			moment
		}
	},
	setup(props) {
		const UPLOAD_FILE_APP_STORE_MODULE_NAME = "app-upload-file"

		// REGISTER MODULE
		if (!store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME))
			store.registerModule(
				UPLOAD_FILE_APP_STORE_MODULE_NAME,
				uploadFileStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME))
				store.unregisterModule(UPLOAD_FILE_APP_STORE_MODULE_NAME)
		})

		const { swalNotification } = useNotifications()
		const { isBidder, isManager, isAdmin, isSuperAdmin } = getUserRoles()

		// REFS
		const tenderId = ref(props.tenderId)
		const competitionId = ref(props.competitionId)
		const tenderProps = ref(props.tenderProps)
		const competitionProps = ref(props.competitionProps)
		const consultationProps = ref(props.consultationProps)
		const proposalProps = ref(props.proposalProps)
		const visitProps = ref(props.visitProps)
		const isPublished = ref(props.isPublished)
		const onlyView = ref(props.onlyView)

		const modal = ref(null)
		const documents = ref([])
		const files = ref([])
		const payload = ref({})

		const dropzone = ref(null)
		const dropzoneOptions = ref({})
		const dropzoneHeaders = ref({})
		const acceptedFiles = ref(props.acceptedFiles)

		const canUpload = ref(true)
		const canWatch = ref(true)
		const canDelete = ref(true)

		const maxFileSize = 26214400 // 25 mb in bytes

		const setDropzoneConfig = () => {
			dropzoneHeaders.value = {
				"Content-Type": "multipart/form-data"
			}
			dropzoneOptions.value = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("accessToken")}`
				},
				url: `${process.env.VUE_APP_HOST}/api/dashboard/files`,
				method: "post",
				paramName: "files",
				autoDiscover: false,
				maxFiles: 10,
				maxFilesize: 25,
				dictFileTooBig:
					"El archivo supera el tamaño permitido. El tamaño máximo son 25MB",
				timeout: 180000,
				maxThumbnailFilesize: 10,
				parallelUploads: 10,
				acceptedFiles:
					tenderProps.value?.isGeneral === true ? "" : acceptedFiles.value,
				autoProcessQueue: true,
				thumbnailWidth: 140,
				thumbnailHeight: 140,
				uploadMultiple: true,
				addRemoveLinks: true,
				dictCancelUpload: "Cancelar Carga"
			}
		}

		const fetchFiles = (params) => {
			store
				.dispatch("app-upload-file/fetchFiles", { payload: params })
				.then((response) => {
					files.value = response.data.files
					modal.value.show()
				})
				.catch((error) => {
					swalNotification(error.response.data.message, false)
				})
		}

		const getGeneralFiles = () => {
			canWatch.value = true
			canUpload.value = true
			canDelete.value = true

			if (isPublished.value || tenderProps.value.isFromInvitation) {
				canUpload.value = false
				canDelete.value = false
			}

			if (isBidder) {
				canUpload.value = false
				canWatch.value = true
				canDelete.value = false
			}

			payload.value = {
				tender_id: tenderId.value,
				is_general: 1
			}

			fetchFiles(payload.value)
		}
		const getSpecificFiles = async () => {
			canUpload.value = false
			canDelete.value = false
			canWatch.value = isPublished.value ? true : false

			if (isBidder) {
				canUpload.value = onlyView.value ? false : true
				canWatch.value = true
				canDelete.value = onlyView.value ? false : true
			}

			const response = await axios.get(`/documents?is_specific=1`)
			documents.value = [...response.data.documents]

			payload.value = {
				competition_id: competitionId.value,
				is_specific: 1
			}

			fetchFiles(payload.value)
		}
		const getVisitFiles = async () => {
			canUpload.value = false
			canWatch.value = true
			canDelete.value = false

			if (isBidder) {
				canUpload.value = true
				canWatch.value = true
				canDelete.value = true
			}

			const response = await axios.get(
				`/visits/documents?tender_id=${tenderId.value}`
			)
			documents.value = [...response.data.documents]

			payload.value = {
				competition_id: competitionId.value,
				visit_id: visitProps.value.visitId,
				is_visit: 1
			}
			fetchFiles(payload.value)
		}

		const getCompetitionFiles = () => {
			modal.value.show()
		}
		const getConsultationFiles = () => {
			let is_consultation = 1
			let is_absolution = 0
			let competition_id = competitionId.value

			canUpload.value = false
			canWatch.value = true
			canDelete.value = false

			if (isBidder) {
				canUpload.value = consultationProps.value.isOwner ? true : false
				canWatch.value = true
				canDelete.value = consultationProps.value.isOwner ? true : false

				if (!consultationProps.value.isOwner) {
					competition_id = consultationProps.value.ownerCompetitionId
				}
			}

			if (consultationProps.value.isAbsolved) {
				canUpload.value = true
				canWatch.value = true
				canDelete.value = true

				if (isBidder) {
					canUpload.value = false
					canWatch.value = true
					canDelete.value = false
					if (!consultationProps.value.isOwner) {
						competition_id = consultationProps.value.ownerCompetitionId
					}
				}
				is_absolution = 1
				is_consultation = 0
			}
			payload.value = {
				consultation_id: consultationProps.value.consultationId,
				competition_id,
				is_consultation,
				is_absolution
			}
			fetchFiles(payload.value)
		}
		const getEconomicFiles = () => {
			canUpload.value = false
			canWatch.value = true
			canDelete.value = false

			if (isBidder) {
				canUpload.value = true
				canWatch.value = true
				canDelete.value = true
			}

			payload.value = {
				competition_id: competitionId.value,
				economic_proposal_bidder_id:
					proposalProps.value.economicProposalBidderId,
				is_economic_proposal: 1
			}

			fetchFiles(payload.value)
		}
		const getisWinnerEvidenceFiles = () => {
			canUpload.value = true
			canWatch.value = true
			canDelete.value = true

			if (isBidder) {
				canUpload.value = false
				canWatch.value = false
				canDelete.value = false
			}

			payload.value = {
				tender_id: tenderId.value,
				is_winner_evidence: 1
			}

			fetchFiles(payload.value)
		}
		const getSigFiles = () => {
			canUpload.value = false
			canWatch.value = true
			canDelete.value = false

			if (isBidder) {
				canUpload.value = true
				canWatch.value = true
				canDelete.value = true
			}

			payload.value = {
				competition_id: competitionId.value,
				proposal_id: proposalProps.value.proposalId
			}

			fetchFiles(payload.value)
		}
		const getTechnicalFiles = () => {
			canUpload.value = false
			canWatch.value = true
			canDelete.value = false

			if (isBidder) {
				canUpload.value = true
				canWatch.value = true
				canDelete.value = true
			}

			payload.value = {
				competition_id: competitionId.value,
				proposal_id: proposalProps.value.proposalId
			}

			fetchFiles(payload.value)
		}

		const reset = () => {
			payload.value = {}
			canUpload.value = false
			canWatch.value = false
			canDelete.value = false
		}

		const getFiles = () => {
			setDropzoneConfig()

			reset()

			if (tenderProps.value.isGeneral) {
				getGeneralFiles()
			} else if (
				tenderProps.value.isSpecific ||
				competitionProps.value.isSpecific
			) {
				getSpecificFiles()
			} else if (tenderProps.value.isWinnerEvidence) {
				getisWinnerEvidenceFiles()
			} else if (competitionProps.value.isCompetition) {
				getCompetitionFiles()
			} else if (consultationProps.value.isConsultation) {
				getConsultationFiles()
			} else if (proposalProps.value.isEconomic) {
				getEconomicFiles()
			} else if (proposalProps.value.isSig) {
				getSigFiles()
			} else if (proposalProps.value.isTechnical) {
				getTechnicalFiles()
			} else if (visitProps.value.isVisit) {
				getVisitFiles()
			}
		}

		const deleteFile = (id) => {
			Vue.swal({
				title: "¿Está seguro(a) de eliminar el archivo?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, eliminar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					store
						.dispatch("app-upload-file/deleteFile", { id })
						.then((response) => {
							getFiles()
							swalNotification(response.data.message)
						})
						.catch(() => {
							swalNotification("Error al eliminar el archivo", false)
						})
				}
			})
		}

		const vsending = (files, xhr, formData) => {
			/* 
			let fileTooLarge = false
			files.forEach((file) => {
				if (file.size > maxFileSize) {
					fileTooLarge = true
				}
			})

			if (fileTooLarge) {
				xhr.onload = () => {} // Desactiva el manejador de éxito
				xhr.onerror = () => {} // Desactiva el manejador de errores
				xhr.abort() // Detiene la solicitud
				swalNotification(
					"El archivo supera el tamaño permitido. El tamaño máximo son 25MB ",
					false
				)
				return // Detiene la ejecución del resto del código
			} */

			const fileData = {
				...payload.value,
				files_count: files.length
			}
			formData.append("fileData", JSON.stringify(fileData))
		}

		const vsuccess = (files, response) => {
			getFiles()

			if (dropzone.value) {
				dropzone.value.removeAllFiles()
			}

			swalNotification(response.message, true, 3500)
		}

		const verror = (files, response, xhr) => {
			getFiles()

			if (dropzone.value) {
				dropzone.value.removeAllFiles()
			}

			const message = response.message || response

			swalNotification(message, false)
		}

		const vremovedfile = (files, response, xhr) => {
			swalNotification("Se ha cancelado la carga")
		}

		return {
			// DROPZONE
			dropzoneOptions,
			dropzoneHeaders,
			dropzone,
			vsending,
			vsuccess,
			verror,
			vremovedfile,

			// REFS
			modal,
			files,
			documents,
			canUpload,
			canWatch,
			canDelete,

			// ACTIONS
			getFiles,
			deleteFile,
			reset
		}
	}
}
</script>

<style lang="scss" scoped>
.description-modal {
	font-size: 1.2rem;
	font-weight: bold;
}

.content-modal {
	font-size: 0.85rem;
}
</style>

<style>
.vue-dropzone {
	border-radius: 15px !important;
	border-style: dotted !important;
	border: 2px solid #3b4253 !important;
}

.dropzone {
	background: transparent !important;
}

.dropzone .dz-preview .dz-image {
	border-radius: 10px !important;
}

.dropzone .dz-preview.dz-image-preview {
	background: transparent !important;
}

.vue-dropzone > .dz-preview .dz-details {
	background: transparent !important;
}

.vue-dropzone > .dz-preview .dz-remove {
	margin-left: 22px !important;
}

.ql-container {
	min-height: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>